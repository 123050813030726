.font-face-jouls {
  font-family: "Joules";
}

.react-tabs__tab-panel--selected {
  display: flex;
  flex: 1;
}

.react-tabs {
  display: flex;
  flex: 1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.fillPage {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
